import React from 'react';
import { loadAccountBalance } from '../utils'
import $ from 'jquery'
import Logos from '../../assets/CoinLogos'
import { InlineLoader } from './ViewComponents'
import numeral from 'numeral'
import { EXPLORER } from '../constants';
import { BsArrowUpRight } from 'react-icons/bs'


export default class AccountInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            balanceDAI: null,
            balanceUSDT: null,
            balanceUSDC: null,
            balanceXUSD: null,
            balanceBUSD: null,
            balanceBLIZZ: null,

        };
    }

    async componentWillMount() {
        let [balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD, balanceBLIZZ] = await Promise.all([loadAccountBalance('DAI', this.props.wallet.account), loadAccountBalance('USDT', this.props.wallet.account), loadAccountBalance('USDC', this.props.wallet.account), loadAccountBalance('xUSD', this.props.wallet.account), loadAccountBalance('BUSD', this.props.wallet.account), loadAccountBalance('BLIZZ', this.props.wallet.account)])
        this.setState({ balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD, balanceBLIZZ })
    }


    async componentWillReceiveProps(nextProps) {
        if (nextProps.wallet !== this.props.wallet) {
            if (nextProps.wallet.status === 'connected') {
                let [balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD, balanceBLIZZ] = await Promise.all([loadAccountBalance('DAI', nextProps.wallet.account), loadAccountBalance('USDT', nextProps.wallet.account), loadAccountBalance('USDC', nextProps.wallet.account), loadAccountBalance('xUSD', nextProps.wallet.account), loadAccountBalance('BUSD', nextProps.wallet.account), loadAccountBalance('BLIZZ', nextProps.wallet.account)])
                this.setState({ balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD, balanceBLIZZ })
            } else {
                this.setState({ balanceDAI: 0, balanceUSDT: 0, balanceUSDC: 0, balanceXUSD: 0, balanceBUSD: 0 })
            }
        }
    }


    render() {
        return <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Account balance</h5>
            </div>
            <div className="modal-body text-center row">
                <div className="col-4 mb-2">

                    <p className="number mb-0 text-brand">
                        {this.state.balanceXUSD === null ? <InlineLoader /> : numeral(this.state.balanceXUSD).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['xUSD']} className="img-inline" alt="xUSD" /> xUSD
                </p>
                </div>
                <div className="col-4 mb-2">

                    <p className="number mb-0 text-brand">
                        {this.state.balanceBLIZZ === null ? <InlineLoader /> : numeral(this.state.balanceBLIZZ).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['BLIZZ']} className="img-inline" alt="BLIZZ" /> BLIZZ
                </p>
                </div>
                <div className="col-4 mb-2">
                    {/* <p>
                    <img src={Logos['ETH']} className="token-icon mx-auto" alt="ETH" />
                </p> */}
                    <p className="number mb-0">
                        {numeral(this.props.wallet.balance / 10 ** 18).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['ETH']} className="img-inline" alt="USDT" /> ETH
                </p>
                </div>
                <div className="col-4 mb-2">

                    <p className="number mb-0">
                        {this.state.balanceDAI === null ? <InlineLoader /> : numeral(this.state.balanceDAI).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['DAI']} className="img-inline" alt="DAI" /> DAI
                </p>
                </div>
                <div className="col-4 mb-2">
                    {/* <p>
                    <img src={Logos['USDT']} className="token-icon mx-auto" alt="USDT" />
                </p> */}
                    <p className="number mb-0">
                        {this.state.balanceUSDT === null ? <InlineLoader /> : numeral(this.state.balanceUSDT).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['USDT']} className="img-inline" alt="USDT" /> USDT
                </p>
                </div>

                <div className="col-4 mb-2">

                    <p className="number mb-0">
                        {this.state.balanceUSDC === null ? <InlineLoader /> : numeral(this.state.balanceUSDC).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['USDC']} className="img-inline" alt="USDC" /> USDC
                </p>
                </div>
                <div className="col-4 mb-2">

                    <p className="number mb-0">
                        {this.state.balanceBUSD === null ? <InlineLoader /> : numeral(this.state.balanceBUSD).format('0,0.[0000]')}
                    </p>
                    <p>
                        <img src={Logos['BUSD']} className="img-inline" alt="BSDC" /> BUSD
                </p>
                </div>



            </div>
            <div className="modal-footer">
                <span className="mr-auto text-sm"><a href={EXPLORER + 'address/' + this.props.wallet.account} target="_blank" rel="noreferrer">View on Etherscan <BsArrowUpRight /></a></span>
                <button
                    onClick={() => { this.props.wallet.reset(); $('#accountInfoModal').modal('hide') }}
                    className="btn btn-outline-brand">
                    Disconnect
            </button>
                <button
                    onClick={() => { $('#accountInfoModal').modal('hide') }}
                    className="btn btn-brand">
                    Close
            </button>
            </div>

        </div>

    }
}