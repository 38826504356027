const BANK_START = 1609891200000
const POOL_START = 1609891200000//1609977600000
const BOND_START = 1610680463000
const BOARDROOM_START = 1610680463000
const EPOCH_TIME = 1609891200000
const MAIN_START = 1610337616000
const STAKING_POOL = [
    {pool: 'LP', stakeToken: 'XUSD_BLIZZ-LP', getToken: 'BLIZZ', stakeTokenName: 'UNI-V2 LP', getTokenName: 'Blizzard Token'},
]

const ETH_MIXERS = [
     {name: '1 ETH', amount: 1, token: 'ETH', pending: false, apr: 800},
     {name: '10 ETH', amount: 10, token: 'ETH', pending:  false, apr: 200},
     {name: '100 ETH', amount: 100, token: 'ETH', pending: true, apr: 50},
     {name: '200 ETH', amount: 200, token: 'ETH', pending: true, apr: 25},

]

const XUSD_MIXERS = [
    {name: '1K', amount: 1000, token: 'xUSD', pending: false, apr: 5000},
    {name: '10K', amount: 10000, token: 'xUSD', pending: false, apr: 1000},
    {name: '50K', amount: 50000, token: 'xUSD', pending: true, apr: 500},
    {name: '100K', amount: 100000, token: 'xUSD', pending: true, apr: 250}
]

const TOTAL_APPROVE = 1000000; 
const CONFIRM_NUMBER = 1
const SOCIAL_LINK ={
    telegram: 'https://t.me/Blizzard_Discussion',
    twitter: 'https://twitter.com/BlizzardCash',
    medium: 'https://blizzardcash-official.medium.com/',
    github: 'https://github.com/blizzardcashofficial/blizzard-contract',

}


// MAINNET
const NETWORK = 1
const TOKEN_INFO = {
        'USDT':{address: '0xdac17f958d2ee523a2206206994597c13d831ec7', decimal: 6},
        'USDC':{address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', decimal: 6},
        'DAI':{address: '0x6b175474e89094c44da98b954eedeac495271d0f', decimal: 18},
        'BUSD':{address: '0x4fabb145d64652a948d72533023f6e7a623c7c53', decimal: 18},
        'xUSD':{address: '0x8a96ad9555ddba080c1ea954b12f8078598a7210', decimal: 18},
        'BLIZZ':{address: '0xB064d9F0D5890F4ffe439D2d4ca3771504f9d7e5', decimal: 18},
        'XUSD_BLIZZ-LP':{address: '0x5DfF696930eF07BF57dcc9EDF22a7707EcF937D8', decimal: 18},
        }
const PAIR_ID = {//PAIR ID on UNISWAP
        'XUSD_BLIZZ-LP': '0x5dff696930ef07bf57dcc9edf22a7707ecf937d8'
}
const EXPLORER = "https://etherscan.io/"
const INFURA = "https://mainet.infura.io/v3/b290a50e9362493e8882b002b9e44eb3"



// TESTNET
// const NETWORK = 3
// const TOKEN_INFO = {
//         'USDT':{address: '0xfbaa19e6c30a988be04701d981a37d016f221f96', decimal: 6},
//         'USDC':{address: '0xecfb506f1b4c414fd8c582627e82b1fcc8dda6f5', decimal: 6},
//         'DAI':{address: '0x5b790219ec629f68f56c6d23fe854e654e89de59', decimal: 18},
//         'BUSD':{address: '0x31699d78c8d9a19888eb2fcdd2f75896a5e6e5a2', decimal: 18},
//         'xUSD':{address: '0x168b4be5dA0ec2c8225B6148B3bC5894E765bc2c', decimal: 18},
//         'BLIZZ':{address: '0x1a0A39B7E286B746582e3288383b6F32f5d7235a', decimal: 18},
//         'XUSD_BLIZZ-LP':{address: '0x27109CEaD32686DC389451Da00b0f08f5D07EdED', decimal: 18},
//         }
// const PAIR_ID = {//PAIR ID on UNISWAP
//         'XUSD_BLIZZ-LP': '0x27109CEaD32686DC389451Da00b0f08f5D07EdED'
// }
// const EXPLORER = "https://ropsten.etherscan.io/"
// const INFURA = "https://ropsten.infura.io/v3/b290a50e9362493e8882b002b9e44eb3"



export {
    BANK_START,
    POOL_START,
    BOND_START,
    STAKING_POOL,
    EPOCH_TIME,
    MAIN_START,
    BOARDROOM_START,
    NETWORK,
    TOKEN_INFO,
    TOTAL_APPROVE,
    CONFIRM_NUMBER,
    EXPLORER,
    INFURA,
    SOCIAL_LINK,
    PAIR_ID,

    XUSD_MIXERS,
    ETH_MIXERS,
}