import React, { Component } from 'react';
import { EPOCH_TIME, BOARDROOM_START } from './constants'
import Countdown from 'react-countdown';
import Logos from "../assets/CoinLogos";

export default class Reward extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }
    render() {

        return (
            <main className="main mb-5">
                <section className="hero">
                    <div className="container position-relative">
                        <h1 className="text-bold text-5">Rewards</h1>
                        {/* <h6 className="text-gray-soft text-brand">Privacy gateway</h6> */}
                    </div>
                </section>
                <section className="pricing text-left">
                    <div className="container">
                        <div className="row">
                            <div className="col-4"><h3 className="text-bold text-2"><img src={Logos['BLIZZ']} className="img-inline text-sm" alt="Cross USD" /> BLIZZ</h3>
                                <div className="card mb-4">
                                    <div className="card-body ">
                                       <div className="d-flex justify-content-between">
                                           <span>Price</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>Total Supply</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>Total Burnt</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>Circulating</span>
                                           <span>-</span>
                                       </div>
                                    
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-4">
                                <h3 className="text-bold text-2"><img src={Logos['ETH']} className="img-inline text-sm" alt="Cross USD" /> ETH</h3>
                                <div className="card mb-4">
                                    <div className="card-body ">
                                        <div className="d-flex justify-content-between text-brand text-bold">
                                           <span>Pool</span>
                                           <span>APY</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>1 ETH</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>10 ETH</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>100 ETH</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>200 ETH</span>
                                           <span>-</span>
                                       </div>
                                       
                                    
                                    </div>
                                </div>

                            </div>


                            <div className="col-4">
                                <h3 className="text-bold text-2"><img src={Logos['xUSD']} className="img-inline text-sm" alt="Cross USD" /> xUSD</h3>
                                <div className="card mb-4">
                                    <div className="card-body ">
                                        <div className="d-flex justify-content-between text-brand text-bold">
                                           <span>Pool</span>
                                           <span>APY</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>1k xUSD</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>10k xUSD</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>50k xUSD</span>
                                           <span>-</span>
                                       </div>
                                       <div className="d-flex justify-content-between">
                                           <span>100k xUSD</span>
                                           <span>-</span>
                                       </div>
                                       
                                    
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </ main>
        )
    }
}