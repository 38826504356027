import XUSD_1000 from "../../abis/XUSD1k.json";
import XUSD_10000 from "../../abis/XUSD10k.json";
import ETH_1 from "../../abis/ETH1.json";
import ETH_10 from "../../abis/ETH10.json";


import { NETWORK } from '../constants'


export const getContract = (coin, amount) => {
    console.log(coin, amount)
    let contractStake;
    let stake;
    if (coin === 'xUSD') {
        //* XUSD_1000
        if (amount === 1000) {
            stake = XUSD_1000.networks[NETWORK];
            if (stake) {
                contractStake = new window.web3.eth.Contract(
                    XUSD_1000.abi,
                    stake.address
                );
            }
        }else if (amount === 10000) {
            stake = XUSD_10000.networks[NETWORK];
            if (stake) {
                contractStake = new window.web3.eth.Contract(
                    XUSD_10000.abi,
                    stake.address
                );
            }
        }
    } else if (coin === 'ETH') {
        //* ETH_1
        if (amount === 1) {
            stake = ETH_1.networks[NETWORK];
            if (stake) {
                contractStake = new window.web3.eth.Contract(
                    ETH_1.abi,
                    stake.address
                );
            }
        }
        //* ETH_1
        if (amount === 10) {
            stake = ETH_10.networks[NETWORK];
            if (stake) {
                contractStake = new window.web3.eth.Contract(
                    ETH_10.abi,
                    stake.address
                );
            }
        }
    }

    console.log('contractStake', coin, contractStake)
    return { depositAddress: stake.address, contractStake }
}