import React from 'react';
import video from '../../assets/cube.mp4'

export default class BrandVideo extends React.Component {
    render(){
        return <div className="bg-default w-100">
        <video playsInline autoPlay="autoplay" loop="loop" muted="muted" className="lighten w-100">
            <source src={video} type="video/mp4" />
        </video>
        </div>
            }
        }