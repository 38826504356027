const Logos = {
      BUSD: require('./busd.svg').default,      
      xUSD: require('./xUSD.png').default,
      BLIZZ: require('./BLIZZ.svg').default,

      USDT: require('./USDT.svg').default,
      USDC: require('./usdc.png').default,
      ETH: require('./ETH.png').default,
      DAI: require('./dai.png').default,
      BAC: require('./BAC.png').default,
      DSD: require('./DSD.png').default,
    }
  
export default Logos;