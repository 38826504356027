import React from 'react';
import { loadAccountBalance } from '../utils'
import $ from 'jquery'
import Logos from '../../assets/CoinLogos'
import { InlineLoader } from './ViewComponents'
import numeral from 'numeral'
import { EXPLORER } from '../constants';
import { BsArrowUpRight } from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Link,
} from "react-router-dom";


export default class AccountInfoHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            balanceXUSD: null,
            balanceBLIZZ: null

        };
    }

    async componentWillMount() {
        let [balanceXUSD, balanceBLIZZ] = await Promise.all([loadAccountBalance('xUSD', this.props.wallet.account), loadAccountBalance('BLIZZ', this.props.wallet.account)])
        this.setState({ balanceXUSD, balanceBLIZZ })
    }


    async componentWillReceiveProps(nextProps) {
        if (nextProps.wallet !== this.props.wallet) {
            if (nextProps.wallet.status === 'connected') {
                let [balanceXUSD, balanceBLIZZ] = await Promise.all([loadAccountBalance('xUSD', nextProps.wallet.account), loadAccountBalance('BLIZZ', nextProps.wallet.account)])
                this.setState({ balanceXUSD, balanceBLIZZ })
            } else {
                this.setState({ balanceXUSD: 0, balanceBLIZZ: 0})
            }
        }
    }


    render() {
        return <div className="row">


            <div className="col-12">
                <p className="row-title d-inline-block">xUSD <img src={Logos['xUSD']} className="img-inline" alt="USDT" /></p>
                <p className="number d-inline-block">
                    {this.state.balanceXUSD === null ? <InlineLoader /> : numeral(this.state.balanceXUSD).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">BLIZZ <img src={Logos['BLIZZ']} className="img-inline" alt="BLIZZ" /></p>
                <p className="number d-inline-block">
                    {this.state.balanceBLIZZ === null ? <InlineLoader /> : numeral(this.state.balanceBLIZZ).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">ETH <img src={Logos['ETH']} className="img-inline" alt="USDT" /></p>
                <p className="number d-inline-block">
                    {numeral(this.props.wallet.balance / 10 ** 18).format('0,0.[0000]')}
                </p>
            </div>
            <div className="col-12">
                <Link to='/CrossUSD/convert' className="btn btn-outline-brand mt-3 btn-block">Convert USDC, DAI, USDT, BUSD to CrossUSD <BsArrowUpRight/></Link>
            </div>      

        </div>

    }
}