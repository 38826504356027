import React, { Component } from 'react';
import Logos from "../assets/CoinLogos";
import Select from 'react-select';
import numeral from 'numeral'
import Web3 from "web3";


import { ETH_MIXERS, XUSD_MIXERS, TOKEN_INFO, TOTAL_APPROVE, CONFIRM_NUMBER, NETWORK, INFURA } from './constants'
import AccountInfoHome from './views/AccountInfoHome'
import { BsPeopleCircle, BsFillBarChartFill, BsArrowUpRight, BsArrowDownRight, BsPeopleFill, BsClockHistory } from "react-icons/bs";
import { ImFire } from "react-icons/im";
import createMemo from './utils/createMemo'
import decodeMemo from './utils/decodeMemo'

import { getContract } from './utils/contractSwitcher'
import { safeAmount, loadAccountBalance, blockTimestamp, loadUniSwapPrice } from './utils'
import moment from 'moment'

import $ from 'jquery'
import LoadingModal from './views/LoadingModal'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
    withRouter
} from "react-router-dom";

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // amount: 1000,
            // token: 'xUSD',
            selectedIndex: 0,
            coinList: [
                { index: 0, value: 'xUSD', label: <><img src={Logos['xUSD']} className="token-icon img-inline" alt="Token" />  Cross USD (xUSD)</> },
                { index: 1, value: 'ETH', label: <><img src={Logos['ETH']} className="token-icon img-inline" alt="Token" />  Ethereum (ETH)</> },
            ],
            mixer: XUSD_MIXERS,
            mixerIndex: 0,
            depWith: 'dep',       //*deposit or withdraw
            depositNote: null,
            txHash: null,
            contractStake: null,
            depositAddress: null, //*contract of deposit and withdraw
            approveStatus: false,
            allowedAmount: 0,
            coinBalance: 0,
            memoInput: null,
            recipientInput: null,
            TVL: 0,
            apr: 0,
            lastedHistory: []
        }

        this.recipientInputChange = this.recipientInputChange.bind(this)
        this.memoInputChange = this.memoInputChange.bind(this)


    }

    recipientInputChange = e => {
        this.setState({ recipientInput: e.target.value });
    };

    memoInputChange = e => {
        this.setState({ memoInput: e.target.value });
    };

    //? this.state.mixer[this.state.mixerIndex] => {name: '1K', amount: 1000, token: 'xUSD', pending: false}
    //? this.state.coinList[this.state.selectedIndex] => { index: 0, value: 'xUSD', label: <><img src={Logos['xUSD']} className="token-icon img-inline" alt="Token" />  Cross USD (xUSD)</> }



    async componentDidMount() {
        this.loadWeb3();
        await this.setState(getContract(this.state.mixer[this.state.mixerIndex].token, this.state.mixer[this.state.mixerIndex].amount))
        this.getCoinInfo()
        this.getContractStatics()
    }


    componentDidUpdate(prevProps) {
        if (prevProps.wallet !== this.props.wallet) {
            if (this.props.wallet.status === 'connected') {
                this.getCoinInfo()
                // this.getxUsdInfo()

            }
        }
    }



    async getContractStatics() {
        let TVL = 0
        let apr =  this.state.mixer[this.state.mixerIndex].apr
        this.setState({apr})

        let lastedHistory = []
        let token = this.state.mixer[this.state.mixerIndex].token
        console.log(this.state.contractStake)
        let totalUser = await this.state.contractStake.methods.getStakedCount().call()
        totalUser = parseInt(totalUser)
        console.log('totalUser', totalUser)


        if (token === 'xUSD'){
            TVL = await loadAccountBalance('xUSD', this.state.depositAddress)
            let blizzPrice = await loadUniSwapPrice('XUSD_BLIZZ-LP')
            console.log('blizzPrice', TOKEN_INFO['XUSD_BLIZZ-LP'].address, blizzPrice)
            // blizzPrice 
            if (totalUser !== 0) {
                apr = blizzPrice * 3600 * 365 / 90 / totalUser / this.state.mixer[this.state.mixerIndex].amount

                this.setState({apr: numeral(apr).format('0,0.[00]')})
            }


        }else{
            TVL = await window.web3.eth.getBalance(this.state.depositAddress)
            TVL = safeAmount(TVL, 18)
        }

        await this.state.contractStake.getPastEvents('Deposit', {
            // filter: { user: this.state.wallet.account },//this.state.wallet.account
            fromBlock: 0,
            toBlock: 'latest'
          })
            .then(async function (events) {
              console.log('event', events)
              for (const i of events) {
                let time = await blockTimestamp(i.blockNumber)
                lastedHistory.push({ time: moment(time*1000).fromNow()});
              }
    
            });
            console.log('lastedHistory', lastedHistory)


            lastedHistory = lastedHistory.reverse().slice(0,5)

        console.log('TVL', TVL)
        this.setState({TVL, totalUser, lastedHistory})

    }

    async switchCoin(coin) {
        // this.setState({ balance: <small className="text-mute">loading...</small>, amount: 0, allowedAmount: <small className="text-mute">loading...</small> })
        if (coin === 'ETH') {
            await this.setState({ mixer: ETH_MIXERS, mixerIndex: 0 })
        } else if (coin === 'xUSD') {
            await this.setState({ mixer: XUSD_MIXERS, mixerIndex: 0 })
        }

        //? Get contract first
        await this.setState(getContract(this.state.mixer[this.state.mixerIndex].token, this.state.mixer[this.state.mixerIndex].amount))

        //? Get contract statictis
        this.getContractStatics()
        this.getCoinInfo()


        // this.setState({ selected: coin })
        // this.getStakeContract(coin)
        // this.getCoinInfo(coin)
        // this.getdUsdInfo()
    }
    async switchMixer(index) {
        await this.setState({ mixerIndex: index });
        //? Get contract first
        await this.setState(getContract(this.state.mixer[this.state.mixerIndex].token, this.state.mixer[this.state.mixerIndex].amount))
        //? Get contract statictis
        this.getContractStatics()
        this.getCoinInfo()

        //? call the right contract
        //? Switch contract here
    }



    loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            const infura = INFURA;
            window.web3 = new Web3(new Web3.providers.HttpProvider(infura));
        }
    }


    async depositCreateMemo() {
        let depositNote = await createMemo()
        // let depositNote = {"memo": `blizzard-${toHex(deposit.preimage, 62)}` , "commitment" : toHex(deposit.commitment)}
        this.setState({ depositNote })
    }




    downloadTxtFile = (data) => {
        const element = document.createElement("a");
        const file = new Blob([data], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        let mixer = this.state.mixer[this.state.mixerIndex]
        element.download = `blizzard-${mixer.amount}-${mixer.token}.txt`;
        document.body.appendChild(element);
        element.click();
    }



    async getCoinInfo() {
        let account = this.props.wallet.account
        let coin = this.state.mixer[this.state.mixerIndex].token
        let coinBalance = 0
        let allowedAmount = 0

        if (account) {
            if (coin === 'xUSD'){
                const web3 = window.web3
                var abi = require('human-standard-token-abi')
                let contract = new web3.eth.Contract(abi, TOKEN_INFO[coin].address)
    
                coinBalance = await contract.methods.balanceOf(account).call()
                coinBalance = parseInt(coinBalance) / 10 ** TOKEN_INFO[coin].decimal
                console.log('coinBalance', coinBalance)
    
                //   coinBalance = safeAmount(coinBalance, TOKEN_INFO[coin].decimal, 5)
                //   console.log('safeAmount', coinBalance)
    
                allowedAmount = await contract.methods.allowance(account, this.state.depositAddress).call()
                allowedAmount = parseInt(allowedAmount) / 10 ** TOKEN_INFO[coin].decimal
            }else if (coin === 'ETH') {
                coinBalance = safeAmount(this.props.wallet.balance, 18)
                allowedAmount = 10000
            }
            


            console.log('coin', coin, 'allowedAmount', allowedAmount, 'coinBalance', coinBalance)
            this.setState({ balance: coinBalance, amount: coinBalance, allowedAmount })
        }



    }



    async approve(coin) {
        const web3 = window.web3;
        let abi = require("human-standard-token-abi");
        let stakeTokenContract = new web3.eth.Contract(abi, TOKEN_INFO[coin].address);
        let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
        this.setState({ approveStatus: false });
        stakeTokenContract.methods
            .approve(this.state.depositAddress, totalApprove)
            .send({
                from: this.props.wallet.account,
                gasPrice: web3.eth.gasPrice,
                gasLimit: web3.eth.getBlock("latest").gasLimit,
            })
            .on("transactionHash", (hash) => {
                this.setState({ txHash: hash, approveStatus: "pending" });
                $("#loadingModal").modal('show');
            })
            .on("confirmation", (confNumber, receipt) => {
                if (confNumber === CONFIRM_NUMBER) {
                    $("#loadingModal").modal('hide');
                    // check balance and allowed amount again
                    //TODO PLS CHECK THIS
                    this.getContractStatics()
                    this.getCoinInfo()
                    //   this.getdUsdInfo()
                    this.setState({ approveStatus: "success" })
                }
            })
            .on("error", (error) => {
                $("#loadingModal").modal('hide');
                this.setState({ approveStatus: "error" })
            });
    }

    convert(n) {
        var sign = +n < 0 ? "-" : "",
          toStr = n.toString();
        if (!/e/i.test(toStr)) {
          return n;
        }
        var [lead, decimal, pow] = n
          .toString()
          .replace(/^-/, "")
          .replace(/^([0-9]+)(e.*)/, "$1.$2")
          .split(/e|\./);
        return +pow < 0
          ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
          : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
      }


    deposit(commitment) {

        const web3 = window.web3;
        console.log('commitment', commitment)
        let value = '0'
        if (this.state.mixer[this.state.mixerIndex].token === 'ETH'){
            value = this.convert(this.state.mixer[this.state.mixerIndex].amount * 10 ** 18).toString()
        }
        console.log('value', value)
        this.state.contractStake.methods
            .deposit(commitment)
            .send({
                value: value,
                from: this.props.wallet.account,
                gasPrice: web3.eth.gasPrice,
                gasLimit: web3.eth.getBlock("latest").gasLimit,
            })
            .on("transactionHash", (hash) => {
                $("#loadingModal").modal('show');
                this.setState({ txHash: hash });
            })
            .on("confirmation", (confNumber, receipt) => {
                if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
                    $("#loadingModal").modal('hide');
                    // check balance and allowed amount again
                    this.getContractStatics()
                    this.getCoinInfo()
                    //   this.getxUsdInfo()
                }
            })
            .on("error", (error) => {
                $("#loadingModal").modal('hide');

                console.log("errr " + error);
            });
    }

    async withdraw() {
        const web3 = window.web3;

        let memo = this.state.memoInput
        if (memo.length !== 135) { return alert('Wrong Memo formart. Please check your Memo!') }
        // console.log('memo.length', memo.length)
        let recipient = this.state.recipientInput
        if (!web3.utils.isAddress(recipient)) { return alert('Wrong recipient address formart. Please check recipient!') }
        let decode = null
        try {
            decode = await decodeMemo(memo, recipient, this.state.contractStake)
        } catch (err) {
            console.log(err)
            return alert(err.message)
        }

        console.log('decodeMemo', decode)

        // if (decode === null){return alert('Something went wrong with your memo!')}

        this.state.contractStake.methods
            .withdraw(decode.Proof, decode.Root, decode.NullifierHash, decode.Recipient, "0x0000000000000000000000000000000000000000", "0", "0")
            .send({
                from: this.props.wallet.account,
                gasPrice: web3.eth.gasPrice,
                gasLimit: web3.eth.getBlock("latest").gasLimit,
            })
            .on("transactionHash", (hash) => {
                $("#loadingModal").modal('show');
                this.setState({ txHash: hash });
            })
            .on("confirmation", (confNumber, receipt) => {
                if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
                    $("#loadingModal").modal('hide');
                    // check balance and allowed amount again
                    this.getContractStatics()
                    this.getCoinInfo()
                    //   this.getxUsdInfo()
                }
            })
            .on("error", (error) => {
                $("#loadingModal").modal('hide');

                console.log("errr " + error);
            });
    }


    
    async stake() {
        const web3 = window.web3;

        let memo = this.state.memoInput
        if (memo.length !== 135) { return alert('Wrong Memo formart. Please check your Memo!') }
        // console.log('memo.length', memo.length)
        let recipient = this.state.recipientInput
        if (!web3.utils.isAddress(recipient)) { return alert('Wrong recipient address formart. Please check recipient!') }
        let decode = null
        try {
            decode = await decodeMemo(memo, recipient, this.state.contractStake)
        } catch (err) {
            console.log(err)
            return alert(err.message)
        }

        console.log('decodeMemo', decode)

        // if (decode === null){return alert('Something went wrong with your memo!')}

        this.state.contractStake.methods
            .stakeWithdraw(decode.Proof, decode.Root, decode.NullifierHash, decode.Recipient)
            .send({
                from: this.props.wallet.account,
                gasPrice: web3.eth.gasPrice,
                gasLimit: web3.eth.getBlock("latest").gasLimit,
            })
            .on("transactionHash", (hash) => {
                $("#loadingModal").modal('show');
                this.setState({ txHash: hash });
            })
            .on("confirmation", (confNumber, receipt) => {
                if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
                    $("#loadingModal").modal('hide');
                    // check balance and allowed amount again
                    this.getContractStatics()
                    this.getCoinInfo()
                    //   this.getxUsdInfo()
                }
            })
            .on("error", (error) => {
                $("#loadingModal").modal('hide');

                console.log("errr " + error);
            });
    }

    async unStake() {
        const web3 = window.web3;

        let memo = this.state.memoInput
        if (memo.length !== 135) { return alert('Wrong Memo formart. Please check your Memo!') }
        // console.log('memo.length', memo.length)
        let recipient = this.state.recipientInput
        if (!web3.utils.isAddress(recipient)) { return alert('Wrong recipient address formart. Please check recipient!') }
        let decode = null
        try {
            decode = await decodeMemo(memo, recipient, this.state.contractStake)
        } catch (err) {
            console.log(err)
            return alert(err.message)
        }

        console.log('decodeMemo', decode)

        // if (decode === null){return alert('Something went wrong with your memo!')}

        this.state.contractStake.methods
            .unstakeAndWithdraw(decode.NullifierHash)
            .send({
                from: this.props.wallet.account,
                gasPrice: web3.eth.gasPrice,
                gasLimit: web3.eth.getBlock("latest").gasLimit,
            })
            .on("transactionHash", (hash) => {
                $("#loadingModal").modal('show');
                this.setState({ txHash: hash });
            })
            .on("confirmation", (confNumber, receipt) => {
                if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
                    $("#loadingModal").modal('hide');
                    // check balance and allowed amount again
                    this.getContractStatics()
                    this.getCoinInfo()
                    //   this.getxUsdInfo()
                }
            })
            .on("error", (error) => {
                $("#loadingModal").modal('hide');

                console.log("errr " + error);
            });
    }




    render() {
        let amount = this.state.mixer[this.state.mixerIndex].amount
        let showApprove = this.props.wallet.status === 'connected' && amount >= this.state.allowedAmount
        let approveButton = showApprove && <button className="btn btn-lg btn-block btn-info" type="button" onClick={(event) => { event.preventDefault(); this.approve(this.state.mixer[this.state.mixerIndex].token); }}>Approve</button>

        let connectButton = this.props.wallet.status !== 'connected' && <button onClick={() => this.props.wallet.connect()} className="btn btn-lg btn-block btn-brand text-bold"> UNLOCK WALLET </button>
        let isInvalidAmount = true
        return (
            <main className="main">
                {/* <section className="hero">
                    <div className="container position-relative">
                        <h1 className="text-bold text-5 ml-5">Cross USD</h1>
                        <h6 className="text-gray-soft text-brand ml-5">Privacy gateway</h6>
                        <div className="cube-video">
                            <BrandVideo />
                        </div>
                    </div>
                </section> */}
                <section className="pricing bg-transparent">

                    <div className="container">
                        <div className="row mt-5">

                            <div className="col-12 col-md-6">
                                <div className="tab-title-container d-flex justify-content-end">
                                    <a href="#" className={"tab-title mr-0 mr-lg-2" + (this.state.depWith === 'with' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ depWith: 'with' }) }}>Withdraw <BsArrowDownRight className="text-brand d-none d-lg-inline-block" /></a>
                                    <a href="#" className={"tab-title mr-0 mr-lg-2" + (this.state.depWith === 'stake' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ depWith: 'stake' }) }}>Stake</a>
                                    <a href="#" className={"tab-title mr-0 mr-lg-2" + (this.state.depWith === 'unstake' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ depWith: 'unstake' }) }}>UnStake</a>
                                    <a href="#" className={"tab-title" + (this.state.depWith === 'dep' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ depWith: 'dep' }) }}>Deposit <BsArrowUpRight className="text-brand d-none d-lg-inline-block" /></a>
                                </div>
                                <div className="card card-focus mb-4 pb-4">
                                    <div className="card-body  text-left">

                                        <p className="card-text text-muted pl-2 mb-0">
                                            token
                                                </p>
                                        <p className="card-text text-left text-muted">
                                            <Select defaultValue={this.state.coinList[this.state.selectedIndex]}
                                                options={this.state.coinList}
                                                className="switcher"
                                                classNamePrefix="sw"
                                                isSearchable={false}
                                                onChange={(selectedOption) => { this.switchCoin(selectedOption.value); this.setState({ selectedIndex: selectedOption.index }) }}

                                            />
                                        </p>
                                        <p className="text-muted  pl-2 mb-0">amount:</p>
                                        <div className="card-text">
                                            <div className="radios">
                                                {this.state.mixer.map((mixer, index) =>
                                                    <div className="radio">
                                                        <label onClick={(e) => { e.preventDefault(); if(!mixer.pending){ this.switchMixer(index) }}}>
                                                            <div className={"checker" + (this.state.mixerIndex == index ? ' checked' : '')}></div><span className={mixer.pending ? 'text-muted' : ''}>{mixer.name}</span>
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {this.state.depWith === 'with' && <>
                                            <p className="card-text text-muted pl-2 mb-0">
                                                note
                                                </p>
                                            <p className="card-text text-sm mb-0">
                                                <textarea className="form-control form-tight number text-muted text-input" rows="4" type="text"
                                                    placeholder="Please enter the note you received when you made the deposit."
                                                    disabled={this.props.wallet.status !== 'connected'}
                                                    value={this.state.memoInput}
                                                    onChange={this.memoInputChange}
                                                />
                                            </p>

                                            {/* {this.state.memoInput} */}
                                            <p className="card-text text-muted pl-2  mt-3 mb-0">
                                                withdrawal address
                                                </p>
                                            <p className="card-text text-sm mb-0">
                                                <input className="form-control form-tight number text-muted text-input" rows="4" type="text"
                                                    placeholder="Enter your address.." disabled={this.props.wallet.status !== 'connected'}
                                                    value={this.state.recipientInput}
                                                    onChange={this.recipientInputChange}
                                                ></input>
                                            </p>
                                            {/* {this.state.recipientInput} */}
                                            <p className="card-text mt-3 pt-3">

                                                {connectButton}
                                                {/* {approveXusdButton} */}
                                                {/* {redeemButton} */}
                                                {this.props.wallet.status === 'connected' && <button className="btn btn-lg btn-block btn-danger" onClick={(e) => { e.preventDefault(); this.withdraw() }}>Withdraw</button>}


                                            </p>
                                        </>}

                                        {this.state.depWith === 'stake' && <>
                                            <p className="card-text text-muted pl-2 mb-0">
                                                note
                                                </p>
                                            <p className="card-text text-sm mb-0">
                                                <textarea className="form-control form-tight number text-muted text-input" rows="4" type="text"
                                                    placeholder="Please enter the note you received when you made the deposit."
                                                    disabled={this.props.wallet.status !== 'connected'}
                                                    value={this.state.memoInput}
                                                    onChange={this.memoInputChange}
                                                />
                                            </p>

                                            {/* {this.state.memoInput} */}
                                            <p className="card-text text-muted pl-2  mt-3 mb-0">
                                                withdrawal address
                                                </p>
                                            <p className="card-text text-sm mb-0">
                                                <input className="form-control form-tight number text-muted text-input" rows="4" type="text"
                                                    placeholder="Enter your address.." disabled={this.props.wallet.status !== 'connected'}
                                                    value={this.state.recipientInput}
                                                    onChange={this.recipientInputChange}
                                                ></input>
                                            </p>
                                            {/* {this.state.recipientInput} */}
                                            <p className="card-text mt-3 pt-3">

                                                {connectButton}
                                                {/* {approveXusdButton} */}
                                                {/* {redeemButton} */}
                                                {this.props.wallet.status === 'connected' && <button className="btn btn-lg btn-block btn-brand" onClick={(e) => { e.preventDefault(); this.stake() }}>Stake</button>}


                                            </p>
                                        </>}

                                        {this.state.depWith === 'unstake' && <>
                                            <p className="card-text text-muted pl-2 mb-0">
                                                note
                                                </p>
                                            <p className="card-text text-sm mb-0">
                                                <textarea className="form-control form-tight number text-muted text-input" rows="4" type="text"
                                                    placeholder="Please enter the note you received when you made the deposit."
                                                    disabled={this.props.wallet.status !== 'connected'}
                                                    value={this.state.memoInput}
                                                    onChange={this.memoInputChange}
                                                />
                                            </p>

                                            {/* {this.state.memoInput} */}
                                            <p className="card-text text-muted pl-2  mt-3 mb-0">
                                                withdrawal address
                                                </p>
                                            <p className="card-text text-sm mb-0">
                                                <input className="form-control form-tight number text-muted text-input" rows="4" type="text"
                                                    placeholder="Enter your address.." disabled={this.props.wallet.status !== 'connected'}
                                                    value={this.state.recipientInput}
                                                    onChange={this.recipientInputChange}
                                                ></input>
                                            </p>
                                            {/* {this.state.recipientInput} */}
                                            <p className="card-text mt-3 pt-3">

                                                {connectButton}
                                                {/* {approveXusdButton} */}
                                                {/* {redeemButton} */}
                                                {this.props.wallet.status === 'connected' && <button className="btn btn-lg btn-block btn-warning" onClick={(e) => { e.preventDefault(); this.unStake() }}>UnStake & Withdraw</button>}


                                            </p>
                                        </>}


                                        {this.state.depWith === 'dep' && <p className="card-text mt-3 pt-3">
                                            {connectButton}


                                            {this.props.wallet.status === 'connected' && !showApprove && <button className="btn btn-lg btn-block btn-success"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (this.state.balance < this.state.mixer[this.state.mixerIndex].amount) {
                                                        return alert('Insufficient balance!')
                                                    };
                                                    this.depositCreateMemo();
                                                    $("#noteModal").modal('show');
                                                }
                                                }>
                                                Deposit
                                                </button>}
                                            {approveButton}


                                            {/* {redeemButton} */}
                                            {/* <button className="btn" onClick={(event) => { event.preventDefault(); this.approve(); }}>Approve</button> */}
                                        </p>}

                                        {/* <a className="btn btn-success btn-sm" onClick={(e) => { e.preventDefault(); createDeposit() }}>createDeposit</a> */}
                                    </div>
                                </div>



                            </div>

                            <div className="col-12 col-md-6 text-left">
                                {this.props.wallet.status === 'connected' && <>
                                    <h3 className="text-bold text-2"><BsPeopleCircle className="text-brand" /> My Balance </h3>
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            {/* <p className="text-bold text-brand">Balance</p> */}
                                            <AccountInfoHome wallet={this.props.wallet} />
                                        </div>
                                    </div>
                                </>}

                                <h3 className="text-bold text-2 mb-2"><BsFillBarChartFill className="text-brand" /> Statistics <span className="badge badge-brand">{this.state.mixer[this.state.mixerIndex].name}</span></h3>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div>
                                            <p className="row-title d-inline-block">Amount</p>
                                            <p className="number d-inline-block">
                                                {numeral(this.state.mixer[this.state.mixerIndex].amount).format('0,0')} {this.state.mixer[this.state.mixerIndex].token}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="row-title d-inline-block">Total User</p>
                                            <p className="number d-inline-block">
                                                {this.state.totalUser} <BsPeopleFill />
                                            </p>
                                        </div>
                                        <div>
                                            <p className="row-title d-inline-block">TVL</p>
                                            <p className="number d-inline-block">
                                                {numeral(this.state.TVL).format('0,0.[0000]')} {this.state.mixer[this.state.mixerIndex].token}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="row-title d-inline-block text-brand">Initial APY</p>
                                            <p className="number d-inline-block text-bold text-brand text-2">
                                                {this.state.apr} %
                                            </p>
                                        </div>
                                        <div>
                                            <p className="row-title d-inline-block text-bold">Latest Deposit</p>

                                        </div>
                                        {this.state.lastedHistory.map((i) => <div className="text-small">
                                            <p className=" text-brand d-inline-block"><BsClockHistory className="mr-2"/></p>
                                            <p className="number d-inline-block">
                                                {i.time}
                                            </p>
                                        </div>)}
                                    </div>
                                </div>

                                <h3 className="text-bold text-2 mb-2"><ImFire className="text-brand" /> Processing Fee Reserve</h3>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div>
                                            <p className="row-title-lg d-inline-block">Ready for Buyback and Burn</p>
                                            <p className="number d-inline-block text-brand text-bold">
                                                {numeral(this.state.TVL*0.5/100).format('0,0.[0000]')} {this.state.mixer[this.state.mixerIndex].token}
                                            </p>
                                        </div>
                                        <button class="btn btn-block btn-brand" disabled> comming soon </button>
                                
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                </section>
                {/* <NoteModal note={this.state.depositNote} /> */}
                <div className="modal fade" id="noteModal" tabIndex="-1" aria-labelledby="noteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content bg-light text-dark">
                            <div className="modal-header">
                                <h5 className="modal-title text-danger">Attention! Save your memo</h5>
                            </div>
                            <div className="modal-body text-brand text-bold">
                                {this.state.depositNote !== null && <p className="py-3 note">
                                    {this.state.depositNote.memo}
                                </p>}
                                <p>
                                </p>
                            </div>
                            <div className="modal-footer">
                                <small className="text-danger">Save this memo carefully! You can not withdraw your money if you lost this memo!</small>

                                <button
                                    onClick={() => { $('#noteModal').modal('hide'); this.deposit(this.state.depositNote.commitment); this.downloadTxtFile(this.state.depositNote.memo) }}
                                    className="btn btn-success btn-block">
                                    Understand & Deposit
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <LoadingModal hash={this.state.txHash} />
            </ main>
        )
    }
}