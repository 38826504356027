const { bigInt } = require('snarkjs')
const crypto = require('crypto')
const circomlib = require('circomlib')

const toHex = (number, length = 32) => '0x' + (number instanceof Buffer ? number.toString('hex') : bigInt(number).toString(16)).padStart(length * 2, '0')
 
async function createDeposit(nullifier, secret) {
    let deposit = { nullifier, secret }
    const pedersenHash = (data) => circomlib.babyJub.unpackPoint(circomlib.pedersenHash.hash(data))[0]
    deposit.preimage = Buffer.concat([deposit.nullifier.leInt2Buff(31), deposit.secret.leInt2Buff(31)])
    deposit.commitment = pedersenHash(deposit.preimage)
    deposit.nullifierHash = pedersenHash(deposit.nullifier.leInt2Buff(31))
    return deposit
}

const createMemo = async () => {
    const rbigint = nbytes => bigInt.leBuff2int(crypto.randomBytes(nbytes))
    const deposit = await createDeposit(rbigint(31), rbigint(31))
    // res.json([{"Memo": `blizzard-${toHex(deposit.preimage, 62)}` , "Commitment" : toHex(deposit.commitment)}])
    return {"memo": `blizzard-${toHex(deposit.preimage, 62)}` , "commitment": toHex(deposit.commitment)}
}

export default createMemo