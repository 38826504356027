import React from 'react';
import { loadAccountBalance } from '../utils'
import $ from 'jquery'
import Logos from '../../assets/CoinLogos'
import { InlineLoader } from './ViewComponents'
import numeral from 'numeral'
import { EXPLORER } from '../constants';
import { BsArrowUpRight } from 'react-icons/bs'


export default class AccountInfoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            balanceDAI: null,
            balanceUSDT: null,
            balanceUSDC: null,
            balanceXUSD: null,
            balanceBUSD: null
        };
    }

    async componentWillMount() {
        let [balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD] = await Promise.all([loadAccountBalance('DAI', this.props.wallet.account), loadAccountBalance('USDT', this.props.wallet.account), loadAccountBalance('USDC', this.props.wallet.account), loadAccountBalance('xUSD', this.props.wallet.account), loadAccountBalance('BUSD', this.props.wallet.account)])
        this.setState({ balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD })
    }


    async componentWillReceiveProps(nextProps) {
        if (nextProps.wallet !== this.props.wallet) {
            if (nextProps.wallet.status === 'connected') {
                let [balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD] = await Promise.all([loadAccountBalance('DAI', nextProps.wallet.account), loadAccountBalance('USDT', nextProps.wallet.account), loadAccountBalance('USDC', nextProps.wallet.account), loadAccountBalance('xUSD', nextProps.wallet.account), loadAccountBalance('BUSD', nextProps.wallet.account)])
                this.setState({ balanceDAI, balanceUSDT, balanceUSDC, balanceXUSD, balanceBUSD })
            } else {
                this.setState({ balanceDAI: 0, balanceUSDT: 0, balanceUSDC: 0, balanceXUSD: 0, balanceBUSD: 0 })
            }
        }
    }


    render() {
        return <div className="row">


            <div className="col-12">
                <p className="row-title d-inline-block">xUSD <img src={Logos['xUSD']} className="img-inline" alt="USDT" /></p>
                <p className="number text-brand text-2 d-inline-block">
                    {this.state.balanceXUSD === null ? <InlineLoader /> : numeral(this.state.balanceXUSD).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">DAI <img src={Logos['DAI']} className="img-inline" alt="DAI" /></p>
                <p className="number d-inline-block">
                    {this.state.balanceDAI === null ? <InlineLoader /> : numeral(this.state.balanceDAI).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">USDC <img src={Logos['USDC']} className="img-inline" alt="USDC" /></p>
                <p className="number d-inline-block">
                    {this.state.balanceUSDC === null ? <InlineLoader /> : numeral(this.state.balanceUSDC).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">USDT <img src={Logos['USDT']} className="img-inline" alt="USDT" /></p>
                <p className="number d-inline-block">
                    {this.state.balanceUSDT === null ? <InlineLoader /> : numeral(this.state.balanceUSDT).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">BUSD <img src={Logos['BUSD']} className="img-inline" alt="BUSD" /></p>
                <p className="number d-inline-block">
                    {this.state.balanceBUSD === null ? <InlineLoader /> : numeral(this.state.balanceBUSD).format('0,0.[0000]')}
                </p>
            </div>

            <div className="col-12">
                <p className="row-title d-inline-block">ETH <img src={Logos['ETH']} className="img-inline" alt="USDT" /></p>
                <p className="number d-inline-block">
                    {numeral(this.props.wallet.balance / 10 ** 18).format('0,0.[0000]')}
                </p>
            </div>
            

        </div>

    }
}