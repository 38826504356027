import React, { Component } from "react";
import logo from "../assets/blizzard-logo.svg";
import { SOCIAL_LINK, TOKEN_INFO, INFURA } from './constants'
import Web3 from "web3";
import {myIP} from './utils'


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Home from "./Home";
import Terms from "./views/Terms"
import Reward from "./Reward";
// import BankInfo from "./BankInfo";
import PoolInfo from "./PoolInfo";

import CrossUSD from "./CrossUSD";
import $ from 'jquery'
import { shortAddress } from './utils/'
import AccountInfo from './views/AccountInfo'
import { AiFillSafetyCertificate } from "react-icons/ai";




import { FaMediumM, FaTwitter, FaTelegramPlane, FaGithub } from "react-icons/fa";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // wallet: null
      localIP: null
    };
  }

   componentDidMount() {
    this.loadWeb3()
    this.loadMyIP()
  }

  async loadMyIP(){
    let localIP = await myIP()
    this.setState({localIP})
  }

  loadWeb3() {
    if (window.ethereum) {
      //incase browser suport ETH
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      //incase re-call
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      //In the rest case
      window.web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
    }
  }

  render() {
    const wallet = this.props.wallet;

    return (
      <Router>
        <header>
          <div className="navbar navbar-expand">
            <div className="container">
              <Link
                to="/"
                className="navbar-brand d-flex align-items-center"
              >
                <img src={logo} className="logo-icon mr-2" alt="blizzard cash" />
              </Link>

              <ul className="navbar-nav ml-auto mr-3 d-none d-lg-flex">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-link"
                    exact
                    activeClassName="active"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/CrossUSD/convert"
                    className="nav-link text-bold"
                    exact
                    activeClassName="active"
                  >
                    CrossUSD
                  </NavLink>
                </li>


                <li className="nav-item">
                  <NavLink
                    to="/pools/LP"
                    className="nav-link"
                    exact
                    activeClassName="active"
                  >
                    xUSD LP
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    to="/reward"
                    className="nav-link"
                    exact
                    activeClassName="active"
                  >
                    Reward
                  </NavLink>
                </li> */}

              </ul>




              <div>
                {wallet.status === "connected" ? (
                  <button
                    onClick={() => $('#accountInfoModal').modal('show')}
                    className="btn btn-outline-brand btn-pill d-none d-md-inline-block"
                  >
                    {shortAddress(wallet.account)}
                  </button>
                ) : (
                    <button
                      onClick={() => { wallet.connect(); console.log('connect', wallet.account) }}
                      // onClick={() => wallet.connect('walletconnect')}
                      className="btn btn-brand text-bold btn-pill d-none d-md-inline-block"
                    >
                      UNLOCK WALLET
                    </button>
                  )}

                <a className="ml-3 d-inline-block d-lg-none" data-toggle="collapse" data-target="#collapseMenu" aria-controls="collapseMenu" aria-expanded="false" aria-label="Toggle navigation">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </a>
              </div>


            </div>

          </div>
          <div className="collapse" id="collapseMenu">
            <ul className="navbar-nav ml-auto mr-3">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  exact
                  activeClassName="active"
                >
                  Home
                  </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/CrossUSD/convert"
                  className="nav-link text-bold"
                  exact
                  activeClassName="active"
                >
                  CrossUSD
                  </NavLink>
              </li>
              <li className="nav-item">
                  <NavLink
                    to="/pools/LP"
                    className="nav-link"
                    exact
                    activeClassName="active"
                  >
                    xUSD LP
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    to="/reward"
                    className="nav-link"
                    exact
                    activeClassName="active"
                  >
                    Reward
                  </NavLink>
                </li> */}
              {/* <li className="nav-item">
                <NavLink
                  to="/bank/DUSDDSC"
                  className="nav-link"
                  exact
                  activeClassName="active"
                >
                  Bank
                  </NavLink>
              </li> */}
              <li className="nav-item">
                {/* <NavLink
                  to="/boardroom"
                  className="nav-link"
                  exact
                  activeClassName="active"
                >
                  Boardroom
                  </NavLink> */}
              </li>
            </ul>
          </div>
        </header>

        <Switch>
          <Route exact path="/">
            <Home wallet={this.props.wallet} />
          </Route>
          {/* <Route path="/bank/:pool">
            <BankInfo wallet={this.props.wallet} />
          </Route> */}
          <Route path="/pools/:pool">
            <PoolInfo wallet={this.props.wallet} />
          </Route>
          <Route exact path="/Reward">
            <Reward wallet={this.props.wallet} />
          </Route>
          <Route exact path="/CrossUSD/:swapRedeem">
            <CrossUSD wallet={this.props.wallet} />
          </Route>
        </Switch>


        <div className="modal fade" id="accountInfoModal" tabIndex="-1" aria-labelledby="accountInfoModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <AccountInfo wallet={this.props.wallet} />
          </div>
        </div>

        <Terms/>


        <footer className="footer mt-auto py-3 ">
          
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.medium}><FaMediumM className="text-secondary text-3 mr-3" /></a>
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.twitter}><FaTwitter className="text-secondary text-3 mr-3" /></a>
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.telegram}><FaTelegramPlane className="text-secondary text-3 mr-3" /></a>
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.github}><FaGithub className="text-secondary text-3" /></a>

          <div className="container py-4 ">
            {/* <p className="text-muted">
              <a target="_blank" rel="noreferrer" href={'https://info.uniswap.org/pair/' + TOKEN_INFO['DSCDUSDLP'].address}>Buy DSC</a> - <a className="text-secondary" target="_blank" rel="noreferrer" href={'https://info.uniswap.org/pair/' + TOKEN_INFO['DSSDUSDLP'].address}>Buy DSS</a> - <a className="text-secondary" target="_blank" rel="noreferrer" href={SOCIAL_LINK.github}>GitHub</a> - <a className="text-secondary" target="_blank" rel="noreferrer" href={SOCIAL_LINK.twitter}>Twitter</a> - <a className="text-secondary" target="_blank" rel="noreferrer" href={SOCIAL_LINK.telegram}>Telegram</a> - <a className="text-secondary" target="_blank" rel="noreferrer" href={SOCIAL_LINK.medium}>Medium</a>
            </p> */}
            <p className="text-muted">
            <span className="text-brand">{this.state.localIP}</span><br/>
            <span className="text-sm">Make sure to use different IP addresses for deposits and withdrawals.<br/>We recommend using a TOR browser or a VPN service.</span>
              </p>

              <p className="text-sm">
              <a className="text-white" target="_blank" rel="noreferrer" href={'https://info.uniswap.org/pair/' + TOKEN_INFO['XUSD_BLIZZ-LP'].address}>Buy BLIZZ</a> ・ 
              {/* <a className="text-white" target="_blank"  href="https://drive.google.com/file/d/1AZlcJmF1WJE1km3p0ajUKP6vTqhcvqh3/view">Audit</a> ・  */}
              <a className="text-white" href="" onClick={(e)=>{e.preventDefault(); $("#termModal").modal('show');}}>Terms of Service</a>
              </p>
            <p className="text-muted">
              Smart contracts build upon Tornado.cash & Yearn.<br/>Interface was inspired by Typhoon
              </p>
            <p className="text-muted">
              <AiFillSafetyCertificate className="text-success text-2"/><br/>
              <small>
                     <strong>Non-Custodial & Secure!</strong><br/> You control your funds, private keys and memos.
              </small>
            </p>
          </div>
        </footer>

      </Router>
    );
  }
}
