// const {NETWORK, INFURA} = require('../constants') 
// const Web3 = require("web3"); 
// const assert = require('assert')
import { bigInt } from 'snarkjs'
import circomlib from 'circomlib'
import merkleTree from '../../lib/MerkleTree'
const MERKLE_TREE_HEIGHT = 20


// const { getContract } = require('./contractSwitcher')



//* CONTRACT SWITCHER
// const ERC20Tornado = require('../../abis/ERC20Tornado.json');
// const web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
// const erc20Tornado = ERC20Tornado.networks[NETWORK]
// const contract = new web3.eth.Contract(ERC20Tornado.abi, erc20Tornado.address)

var CONTRACT = null 



/** BigNumber to hex string of specified length */
const toHex = (number, length = 32) => '0x' + (number instanceof Buffer ? number.toString('hex') : bigInt(number).toString(16)).padStart(length * 2, '0')
 
async function parseNote(noteString) {
    const noteRegex = /blizzard-0x(?<note>[0-9a-fA-F]{124})/g
    const match = noteRegex.exec(noteString)
    const buf = Buffer.from(match.groups.note, 'hex')
    const nullifier = bigInt.leBuff2int(buf.slice(0, 31))
    const secret = bigInt.leBuff2int(buf.slice(31, 62))
    const deposit = await createDeposit(nullifier, secret)
    console.log(deposit)
    return deposit
}

async function generateMerkleProof(deposit) {
    console.log('Getting contract state...')
    // const web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
    // const erc20Tornado = ERC20Tornado.networks[NETWORK]
    // const contract = new web3.eth.Contract(ERC20Tornado.abi, erc20Tornado.address)
    const events = await CONTRACT.getPastEvents('Deposit', { fromBlock: 0, toBlock: 'latest' })
    const leaves = events 
      .sort((a, b) => a.returnValues.leafIndex - b.returnValues.leafIndex) // Sort events in chronological order
      .map(e => e.returnValues.commitment)
    const tree = new merkleTree(MERKLE_TREE_HEIGHT, leaves) 
    // // Find current commitment in the tree
    let depositEvent = events.find(e => e.returnValues.commitment === toHex(deposit.commitment))
    let leafIndex = depositEvent ? depositEvent.returnValues.leafIndex : -1
    // // Validate that our data is correct (optional)
    // const isValidRoot = await CONTRACT.methods.isKnownRoot(toHex(await tree.root())).call()
    // const isSpent = await CONTRACT.methods.isSpent(toHex(deposit.nullifierHash)).call()
    
    // assert(isValidRoot === true, 'Merkle tree is corrupted')
    // assert(isSpent === false, 'The note is already spent')
    // assert(leafIndex >= 0, 'The deposit is not found in the tree')
   
    // Compute merkle proof of our commitment
    return await tree.path(leafIndex)
} 

async function generateSnarkProof(deposit, recipient) {
    // Compute merkle proof of our commitment
    const { root, path_elements, path_index } = await generateMerkleProof(deposit)
    const Proof = "0x1173bbf73950a978a7c76fb5ae5d88b95d155efad023150add0de67fc55f6922147162b932df5e421057335a7cc689c9704ecaf7bb86abe2c4cf8c8be21cd4c72e458bf9e98e6eea6d770f47899aa87e92068068b24d59a203f75778359b185e1af545e4654285595ae6aba293f6bb962f1ca3c3063031cc2a281ee7dfd6cea306fc894d8a1677bbd5fe85c11d4c9352bf392db68de23ece4ade1271ad62db7116409f184683ff6bb5b4cd3a2115b3f0ba20b3e7947846656d73966886c808ed0a55eed3c564ea39032e0f9a0aeadd660b04f633ea174d72ae5dbcba0162751e110135649a8e1eb2fa9b08be09cbbebe52a514e14f2cf5a57866ccee90e61ffb"
    const Root = toHex(root);
    const NullifierHash = toHex(deposit.nullifierHash)
    const Recipient =  toHex(bigInt(recipient), 20)

    return { Proof, Root, NullifierHash, Recipient }

  }

async function createDeposit(nullifier, secret) {
    let deposit = { nullifier, secret }
    const pedersenHash = data => circomlib.babyJub.unpackPoint(circomlib.pedersenHash.hash(data))[0]
    deposit.preimage = Buffer.concat([deposit.nullifier.leInt2Buff(31), deposit.secret.leInt2Buff(31)])
    deposit.commitment = pedersenHash(deposit.preimage)
    deposit.nullifierHash = pedersenHash(deposit.nullifier.leInt2Buff(31))
    return deposit
}

const decodeMemo = async (note, recipient, contract) => {
    CONTRACT = contract
    const deposit = await parseNote(note)
    // console.log('decode', deposit)
    return await generateSnarkProof(deposit, recipient)
}

export default decodeMemo
