import React from 'react';
import { EXPLORER } from '../constants'
import Tornado from './Tornado'
import { BsArrowUpRight } from "react-icons/bs";



export default class LoadingModal extends React.Component {
    render(){
        return <div className="modal fade" id="loadingModal" tabIndex="-1" aria-labelledby="loadingModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body text-center py-5">

              {/* <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
              <p>
                <Tornado />
              </p>
              <p className="loading-title mt-5">processing...</p>
              <p>
                <a href={EXPLORER + 'tx/' + this.props.hash} target="_blank" rel="noreferrer"><small>View Tx on Etherscan <BsArrowUpRight /></small></a>
              </p>
            </div>

          </div>
        </div>
      </div>
            }
        }