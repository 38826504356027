import React, { Component } from 'react';
import { INFURA, TOKEN_INFO, TOTAL_APPROVE, CONFIRM_NUMBER, NETWORK, EXPLORER } from './constants'
import Logos from "../assets/CoinLogos";
import SWAP_USDT from "../abis/SwapUSDTxUSD.json";
import SWAP_USDC from "../abis/SwapUSDCxUSD.json";
import SWAP_DAI from "../abis/SwapDAIxUSD.json";
import SWAP_BUSD from "../abis/SwapBUSDxUSD.json";

import $ from 'jquery'
import Web3 from "web3";
import numeral from 'numeral'
import BrandVideo from './views/BrandVideo'
import AccountInfoCard from './views/AccountInfoCard'
import LoadingModal from './views/LoadingModal'
import { safeAmount } from './utils'


// import PoolSwapInfo from './views/PoolSwapInfo'
import Select from 'react-select';
import { BsArrowRight, BsPeopleCircle } from "react-icons/bs";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  withRouter
} from "react-router-dom";


class CrossUSD extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wallet: this.props.wallet,
      selected: 'USDC',
      selectedIndex: 0,
      balance: <small className="text-mute">loading...</small>,
      amount: 0,
      contractStake: null,
      txHash: null,
      approveStatus: null,
      allowedAmount: 0,

      dUsdApproveStatus: null,
      xUsdAmount: 0,
      xUsdBalance: 0,
      xUsdAllowedAmount: 0,
      poolAvailable: 0,

      options: [
        { index: 0, value: 'USDC', label: <><img src={Logos['USDC']} className="token-icon img-inline" alt="Token" />  USD Coin (USDC)</> },
        { index: 1, value: 'DAI', label: <><img src={Logos['DAI']} className="token-icon img-inline" alt="Token" />  Marker DAO (DAI)</> },
        { index: 2, value: 'USDT', label: <><img src={Logos['USDT']} className="token-icon img-inline" alt="Token" />  Tether (USDT)</> },
        { index: 3, value: 'BUSD', label: <><img src={Logos['BUSD']} className="token-icon img-inline" alt="Token" />  Binance USD (BUSD)</> },
      ]



    }
    this.handleChange = this.handleChange.bind(this);
    this.handledUsdChange = this.handledUsdChange.bind(this);


  }

  handleChange = e => {
    this.setState({ amount: e.target.value });
  };

  handledUsdChange = e => {
    this.setState({ xUsdAmount: e.target.value });
  };

  componentDidMount() {

    this.loadWeb3();
    this.getStakeContract(this.state.selected)
    if (this.props.wallet.status === 'connected') {
      this.getCoinInfo(this.state.selected)
      this.getxUsdInfo()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wallet !== this.props.wallet) {
      if (this.props.wallet.status === 'connected') {
        this.getCoinInfo(this.state.selected)
        this.getxUsdInfo()
      }
      this.setState({
        wallet: this.props.wallet
      });

    }
  }

  switchCoin(coin) {
    this.setState({ balance: <small className="text-mute">loading...</small>, amount: 0, allowedAmount: <small className="text-mute">loading...</small> })
    this.setState({ selected: coin })
    this.getStakeContract(coin)
    this.getCoinInfo(coin)
    this.getxUsdInfo()
  }




  loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      const infura = INFURA;
      window.web3 = new Web3(new Web3.providers.HttpProvider(infura));
    }
  }


  setMax() {
    this.setState({ amount: this.state.balance })
  }

  setMaxXUSD() {
    this.setState({ xUsdAmount: this.state.xUsdBalance })
  }



  async getCoinInfo(coin) {
    let account = this.props.wallet.account
    if (account) {
      const web3 = window.web3
      var abi = require('human-standard-token-abi')
      let contract = new web3.eth.Contract(abi, TOKEN_INFO[coin].address)

      let coinBalance = await contract.methods.balanceOf(account).call()
      // coinBalance = parseInt(coinBalance) / 10 ** TOKEN_INFO[coin].decimal
      // console.log('coinBalance', coinBalance)

      coinBalance = safeAmount(coinBalance, TOKEN_INFO[coin].decimal, 5)
      console.log('safeAmount', coinBalance)

      let allowedAmount = await contract.methods.allowance(account, this.state.stakeContractAddress).call()
      allowedAmount = parseInt(allowedAmount) / 10 ** TOKEN_INFO[coin].decimal

      let poolAvailable = await contract.methods.balanceOf(this.state.stakeContractAddress).call()
      poolAvailable = parseInt(poolAvailable) / 10 ** TOKEN_INFO[coin].decimal

      // console.log(coin, coinBalance, Math.floor(coinBalance), allowedAmount, poolAvailable)
      this.setState({ balance: coinBalance, amount: coinBalance, allowedAmount, poolAvailable })
    }
  }

  async getxUsdInfo() {
    let account = this.props.wallet.account
    if (account) {
      const web3 = window.web3
      var abi = require('human-standard-token-abi')
      let xUsdBalance = 0
      var contract

      contract = new web3.eth.Contract(abi, TOKEN_INFO['xUSD'].address)

      xUsdBalance = await contract.methods.balanceOf(account).call()

      xUsdBalance = safeAmount(xUsdBalance, TOKEN_INFO['xUSD'].decimal, 5)
      
      // console.log('xUsdBalance', xUsdBalance)

      let xUsdAmount = xUsdBalance

      this.setState({ xUsdBalance, xUsdAmount })
    }
  }

  getStakeContract(coin) {
    let contractStake;
    let stake;

    if (coin === 'USDT') {
      stake = SWAP_USDT.networks[NETWORK];
      if (stake) {
        contractStake = new window.web3.eth.Contract(
          SWAP_USDT.abi,
          stake.address
        );
      }
    } else if (coin === 'USDC') {
      stake = SWAP_USDC.networks[NETWORK];
      if (stake) {
        contractStake = new window.web3.eth.Contract(
          SWAP_USDC.abi,
          stake.address
        );
      }
    } else if (coin === 'DAI') {
      stake = SWAP_DAI.networks[NETWORK];
      if (stake) {
        contractStake = new window.web3.eth.Contract(
          SWAP_DAI.abi,
          stake.address
        );
      }
    } else if (coin === 'BUSD') {
    stake = SWAP_BUSD.networks[NETWORK];
    if (stake) {
      contractStake = new window.web3.eth.Contract(
        SWAP_BUSD.abi,
        stake.address
      );
    }
  }


    console.log('contractStake', coin, contractStake)

    this.setState({ stakeContractAddress: stake.address, contractStake });
  }


  async approve(coin) {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(abi, TOKEN_INFO[coin].address);
    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ approveStatus: false });
    stakeTokenContract.methods
      .approve(this.state.stakeContractAddress, totalApprove)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.setState({ txHash: hash, approveStatus: "pending" });
        $("#loadingModal").modal('show');
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again
          this.getCoinInfo(coin)
          this.getxUsdInfo()
          this.setState({ approveStatus: "success" })
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');
        this.setState({ approveStatus: "error" })
      });
  }


  async swap() {
    //get SPONSOR ID
    if (this.state.amount <= 0) {
      return alert("Invalid Amount: Please check your amount!");
    }
    if (this.state.amount > this.state.balance) {
      return alert("Insufficient balance!");
    }

    this.depositBank(this.state.selected)

  }

  depositBank(coin) {
    const web3 = window.web3;
    console.log('amount', this.state.amount)
    let stakeAmountDecimal = this.convert(this.state.amount * 10 ** TOKEN_INFO[coin].decimal).toString()
    console.log('stakeAmountDecimal', stakeAmountDecimal)
    this.state.contractStake.methods
      .convertToken(stakeAmountDecimal)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        $("#loadingModal").modal('show');
        this.setState({ txHash: hash });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again

          this.getCoinInfo(this.state.selected)
          this.getxUsdInfo()
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');

        console.log("errr " + error);
      });
  }

  async approvedUsd() {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(abi, TOKEN_INFO['xUSD'].address);
    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ dUsdApproveStatus: false });
    stakeTokenContract.methods
      .approve(this.state.stakeContractAddress, totalApprove)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.setState({ txHash: hash, dUsdApproveStatus: "pending" });
        $("#loadingModal").modal('show');
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again
          this.getxUsdInfo()
          this.setState({ dUsdApproveStatus: "success" })
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');
        this.setState({ dUsdApproveStatus: "error" })
      });
  }

  async redeem() {
    if (this.state.xUsdAmount <= 0) {
      return alert("Invalid Amount: Please check your amount!");
    }
    if (this.state.xUsdAmount > this.state.xUsdBalance) {
      return alert("Insufficient balance!");
    }
    if (this.state.xUsdAmount > this.state.poolAvailable) {
      return alert("Insufficient pool balance, please choose a different pool or reduce the amount.");
    }

    // check balance of pool selected

    this.widthdrawBank(this.state.selected)

  }


  widthdrawBank() {
    const web3 = window.web3;
    let stakeAmountDecimal = this.convert(this.state.xUsdAmount * 10 ** TOKEN_INFO['xUSD'].decimal).toString()
    this.state.contractStake.methods
      .revertToken(stakeAmountDecimal)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        $("#loadingModal").modal('show');
        this.setState({ txHash: hash });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again

          this.getCoinInfo(this.state.selected)
          this.getxUsdInfo()
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');

        console.log("errr " + error);
      });
  }

  convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
      "0." +
      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
      lead +
      decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }


  render() {
    let showApprove = this.props.wallet.status === 'connected' && this.state.amount >= this.state.allowedAmount
    let approveButton = showApprove && <button className="btn btn-lg btn-block btn-info" type="button" onClick={(event) => { event.preventDefault(); this.approve(this.state.selected); }}>
      {this.state.approveStatus === "pending" ? "Waiting..." : "Approve " + this.state.selected}
    </button>
    let showSwap = this.props.wallet.status === 'connected' && this.state.amount <= this.state.allowedAmount
    let swapButton = showSwap && <button className="btn btn-lg btn-block btn-success" type="button" onClick={(event) => { event.preventDefault(); this.swap(); }} disabled={this.state.balance === 0}> Convert {this.state.selected} → xUSD </button>


    // let showdUsdApprove = this.props.wallet.status === 'connected' && this.state.xUsdAmount >= this.state.xUsdAllowedAmount
    // let approvedUsdButton = showdUsdApprove && <button className="btn btn-lg btn-block btn-warning" type="button" onClick={(event) => { event.preventDefault(); this.approvedUsd(); }}>
    //     {this.state.dUsdApproveStatus === "pending" ? "Waiting..." : <>"Approve dUSD" + {this.state.xUsdAmount} + {this.state.xUsdAllowedAmount}</>}
    // </button>
    // let showRedeem = this.props.wallet.status === 'connected' && this.state.xUsdAmount <= this.state.xUsdAllowedAmount, 
    let showRedeem = this.props.wallet.status === 'connected'
    let redeemButton = showRedeem && <button className="btn btn-lg btn-block btn-danger" type="button" onClick={(event) => { event.preventDefault(); this.redeem(); }} disabled={this.state.xUsdBalance === 0}> Revert xUSD → {this.state.selected} </button>
    let isInvalidAmount = this.state.amount < 0 || this.state.amount > this.state.balance




    let connectButton = this.state.wallet.status !== 'connected' && <button onClick={() => this.state.wallet.connect()} className="btn btn-lg btn-block btn-brand text-bold"> UNLOCK WALLET </button>

    return (
      <main className="main mb-5">
        <section className="hero">
          <div className="container position-relative">
            <h1 className="text-bold text-5 ml-5">CrossUSD</h1>
            <h6 className="text-gray-soft text-brand ml-5">Privacy gateway</h6>
            <div className="cube-video">
              <BrandVideo />
            </div>
          </div>
        </section>
        <section className="pricing">
          <div className="container">
            <div className="row">

              <div className="col-12 col-md-6">
                <div className="tab-title-container d-flex justify-content-end">
                  <NavLink to="/CrossUSD/revert" className="tab-title mr-2" activeClassName="active">Revert</NavLink>
                  <NavLink to="/CrossUSD/convert" className="tab-title" activeClassName="active">Convert to CrossUSD</NavLink>
                </div>

                {/* REDEEM */}
                {this.props.match.params.swapRedeem === 'revert' &&
                  <div className="card card-focus mb-4 pb-4">
                    <div className="card-body">
                      {this.props.wallet.status !== 'connected' ?
                        <p className="card-text my-5">{connectButton}</p> : <>
                          <p className="card-text text-left text-muted pl-2 mb-0">
                            Revert to:
                        </p>
                          <p className="card-text text-left text-muted">
                            <Select defaultValue={this.state.options[this.state.selectedIndex]}
                              options={this.state.options}
                              className="switcher"
                              classNamePrefix="sw"
                              isSearchable={false}
                              onChange={(selectedOption) => { this.switchCoin(selectedOption.value); this.setState({ selectedIndex: selectedOption.index }) }}

                            />
                          </p>


                          <p className="text-muted mb-0">summary</p>
                          <p className="number text-2 py-3">
                            {numeral(this.state.xUsdAmount).format('0,0.[00]')} <sup className="text-muted">xUSD</sup> <BsArrowRight className="text-brand" /> {numeral(this.state.xUsdAmount).format('0,0.[00]')} <sup className="text-muted">{this.state.selected}</sup>
                          </p>
                          <p className="card-text text-left text-muted text-sm mb-0">
                            Amount {this.state.selected}:
                                    {this.state.xUsdAmount !== this.state.xUsdBalance && <a href="#" onClick={(e) => { e.preventDefault(); this.setMaxXUSD() }} className="float-right">All {numeral(this.state.xUsdBalance).format('0,0.[00]')} xUSD</a>}
                          </p>
                          <p className="card-text"><input
                            type="number"
                            name="stakeAmount"
                            className={"form-control text-center stake-input number " + (isInvalidAmount ? " border-danger" : '')}
                            value={this.state.xUsdAmount}
                            onChange={this.handledUsdChange}
                          /></p>
                          <p className="card-text mt-3 pt-3">
                            {connectButton}
                            {/* {approvedUsdButton} */}
                            {redeemButton}
                            {/* <button className="btn" onClick={(event) => { event.preventDefault(); this.approve(); }}>Approve</button> */}
                          </p>
                          <p className="card-text text-muted">
                            Pool available: <span className="number">{numeral(this.state.poolAvailable).format('0,0.[00]')}</span> {this.state.selected}
                          </p>

                        </>}


                    </div>
                  </div>
                }

                {/* SWAP */}
                {this.props.match.params.swapRedeem !== 'revert' &&
                  <div className="card card-focus mb-4 pb-4">
                    <div className="card-body">
                      {this.props.wallet.status !== 'connected' ? <p className="card-text my-5">{connectButton}</p> : <>
                        <p className="card-text text-left text-muted pl-2 mb-0">
                          Convert token:
                        </p>
                        <p className="card-text text-left text-muted">

                          <Select defaultValue={this.state.options[this.state.selectedIndex]}
                            options={this.state.options}
                            className="switcher"
                            classNamePrefix="sw"
                            isSearchable={false}
                            onChange={(selectedOption) => { this.switchCoin(selectedOption.value); this.setState({ selectedIndex: selectedOption.index }) }}
                          />
                        </p>
                        <p className="text-muted mb-0">summary</p>
                        <p className="number text-2 py-3">
                          {numeral(this.state.amount).format('0,0.[00]')} <sup className="text-muted">{this.state.selected}</sup> <BsArrowRight className="text-brand" /> {numeral(this.state.amount).format('0,0.[00]')} <sup className="text-muted">xUSD</sup>
                                </p>
                        <p className="card-text text-left text-muted mb-0">
                          <small>Amount {this.state.selected}:</small>
                                    {this.state.amount !== this.state.balance && <a href="#" onClick={(e) => { e.preventDefault(); this.setMax() }} className="float-right badge badge-brand badge-pill">All {numeral(this.state.balance).format('0,0.[00]')} {this.state.selected}</a>}
                        </p>
                        <p className="card-text"><input
                          type="number"
                          name="stakeAmount"
                          className={"form-control text-center stake-input number " + (isInvalidAmount ? " border-danger" : '')}
                          value={this.state.amount}
                          onChange={this.handleChange}
                        /></p>

                        {isInvalidAmount && <p className="card-text text-danger">
                          Invalid amount
                                </p>}
                        {!isInvalidAmount && <p className="card-text mt-3 pt-3">

                          {approveButton}
                          {this.state.amount != 0 && swapButton}
                          {/* <button className="btn" onClick={(event) => { event.preventDefault(); this.approve(); }}>Approve</button> */}
                        </p>}
                      </>}

                    </div>
                  </div>
                }
              </div>
              <div className="col-12 col-md-6 text-left">
                {this.props.wallet.status === 'connected' && <>
                  <h3 className="text-bold text-2"><BsPeopleCircle className="text-brand" /> My Balance </h3>
                  <div className="card mb-4">
                    <div className="card-body">
                      {/* <p className="text-bold text-brand">Balance</p> */}
                      <AccountInfoCard wallet={this.props.wallet} />
                    </div>
                  </div>
                </>}

                {/* <h3 className="text-bold text-2 mb-2"> <BsFillBarChartFill className="text-brand" /> Statistics</h3>
                <div className="card mb-4">
                  <div className="card-body">
                      <p className="text-bold text-brand">Pool Info</p>
                      <PoolSwapInfo wallet={this.props.wallet} />
                  </div>
                </div> */}

              </div>


            </div>


          </div>
        </section>




        <LoadingModal hash={this.state.txHash}/>



      </ main>


    )
  }
}

export default withRouter(CrossUSD);