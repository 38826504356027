import './App.css';
import React from 'react';
import {NETWORK} from './components/constants'
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/popper.min.js';
import 'bootstrap'
import './assets/style/custom.css';
import { useWallet, UseWalletProvider } from 'use-wallet'

import BrandVideo from './components/views/BrandVideo'

function App() {
  const wallet = useWallet()
  return (
    <React.Fragment>
    {/* <div className="App d-flex flex-column">
      <Header wallet={wallet} />
    </div> */}
    <div className="App d-flex flex-column my-auto">
      <div className="my-auto">
      <div className="maintain-video mx-auto">
              <BrandVideo />
            </div>
        <h2 className="text-brand text-bold">We will be back soon!</h2>
        Blizzard.cash is temporarily closed for maintenance.<br/>
        We will be back soon! Thank you!
        </div>
    </div>
    </React.Fragment>
  );
}

export default () => (
  <UseWalletProvider chainId={NETWORK}>
    <App />
  </UseWalletProvider>)