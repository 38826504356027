import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { STAKING_POOL, TOKEN_INFO, TOTAL_APPROVE, CONFIRM_NUMBER, EXPLORER, INFURA, NETWORK } from './constants'
import Logos from "../assets/CoinLogos";
import $ from 'jquery'
import Web3 from "web3";
import LP_CONTRACT from "../abis/UniswapLPReward.json";
// import DSSDUSDLPDSS_CONTRACT from "../abis/pool/PoolDSSdUSD.json";//UPDATE WHEN AVAILABLE
import numeral from 'numeral'
import moment from 'moment'
import LoadingModal from './views/LoadingModal';
import { blockTimestamp, shortAddress, safeAmount } from './utils'
import { BsArrowUpRight } from "react-icons/bs";



class PoolInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pool: null,
      poolInfo: null,
      wallet: this.props.wallet,
      approveStatus: false,
      stakeTokenAddress: null,
      stakeTokenDecimal: 0,
      contractStake: null,
      stakeAmount: 0,
      unStakeAmount: 0,
      availableStakedAmount: 0, //* avaivale balance to withdraw
      withdrawAmount: 0,
      allowedAmount: 0,
      balance: 0,
      balanceStatus: false,
      stakeStatus: null,
      earned: 0,
      totalEarned: 0,
      stakedAmount: 0,
      historyArray: [],
      settleStatus: 0,
      stakeUnstake: 'stake',
      historyClaim: 'claim'

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleUnstateChange = this.handleUnstateChange.bind(this);

  }

  handleChange = e => {
    this.setState({ stakeAmount: e.target.value });
  };

  handleUnstateChange = e => {
    this.setState({ unStakeAmount: e.target.value });
  };

  componentDidMount() {
    this.loadWeb3();
    this.setState({ pool: this.props.match.params.pool });
    this.poolValidator();
    this.getStakeContract();



  }

  componentDidUpdate(prevProps) {
    if (prevProps.wallet !== this.props.wallet) {
      if (this.props.wallet.status === 'connected') {
        this.getUserInfo(this.state.poolInfo.stakeToken, this.props.wallet.account)
      }
      this.setState({
        wallet: this.props.wallet
      });

    }
  }


  async getUserInfo(coin, account) {
    this.setState({ balanceStatus: true })
    const web3 = window.web3
    var abi = require('human-standard-token-abi')
    let coinBalance = 0
    let allowedAmount = 0

    this.getEarned()
    this.getStakeHistory()

    if (coin === 'XUSD_BLIZZ-LP') {
      var contract = new web3.eth.Contract(abi, TOKEN_INFO['XUSD_BLIZZ-LP'].address)
      coinBalance = await contract.methods.balanceOf(account).call()
      console.log('safeAmount', safeAmount(coinBalance, TOKEN_INFO[this.state.poolInfo.stakeToken].decimal, 12))
      coinBalance = safeAmount(coinBalance, TOKEN_INFO[this.state.poolInfo.stakeToken].decimal, 12)
      // coinBalance = parseInt(coinBalance) / 10 ** TOKEN_INFO['XUSD_BLIZZ-LP'].decimal
      // console.log('coinBalance', coinBalance)
      allowedAmount = await contract.methods.allowance(account, this.state.stakeContractAddress).call()
      allowedAmount = parseInt(allowedAmount) / 10 ** TOKEN_INFO['XUSD_BLIZZ-LP'].decimal
      this.setState({ balance: coinBalance, stakeAmount: coinBalance, allowedAmount, balanceStatus: false })
    } else {
      this.setState({ balance: 0 })
    }
  }

  loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      const infura = INFURA;
      window.web3 = new Web3(new Web3.providers.HttpProvider(infura));
    }
  }

  async poolValidator() {
    let pool = STAKING_POOL.filter(
      (pool) => pool.pool === this.props.match.params.pool
    );
    if (pool.length !== 0) {
      console.log('pool', pool)
      await this.setState({ poolInfo: pool[0], stakeTokenAddress: TOKEN_INFO[pool[0].stakeToken].address, stakeTokenDecimal: TOKEN_INFO[pool[0].stakeToken].decimal });
      //   console.log('poolInfo', this.state.poolInfo, this.state.stakeTokenAddress, this.state.stakeTokenDecimal)
      if (this.state.wallet.status === 'connected') {
        console.log(pool[0].stakeToken, this.state.wallet.account)
        this.getUserInfo(pool[0].stakeToken, this.state.wallet.account)
      }
    } else {
      alert('Pool not found')
      window.location.replace("/");
    }
  }

  getStakeContract() {
    let stake = null;
    let contractStake;

    switch (true) {

      case (this.props.match.params.pool === "LP"):
        stake = LP_CONTRACT.networks[NETWORK];
        if (stake) {
          contractStake = new window.web3.eth.Contract(
            LP_CONTRACT.abi,
            stake.address
          );
        }
        break;

    }

    console.log('contractStake', contractStake)

    this.setState({ stakeContractAddress: stake.address, contractStake });
  }

  async approve() {
    const web3 = window.web3;
    let abi = require("human-standard-token-abi");
    let stakeTokenContract = new web3.eth.Contract(abi, this.state.stakeTokenAddress);
    console.log('stakeTokenContract', stakeTokenContract);

    console.log('token', this.state.stakeTokenAddress, 'contract', this.state.stakeContractAddress,)

    let totalApprove = web3.utils.toWei(TOTAL_APPROVE.toString(), "ether");
    this.setState({ approveStatus: false });

    stakeTokenContract.methods
      .approve(this.state.stakeContractAddress, totalApprove)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        this.setState({ txHash: hash, approveStatus: "pending" });
        $("#loadingModal").modal('show');
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again

          this.setState({ approveStatus: "success" })
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');
        this.setState({ approveStatus: "error" })
      });
  }

  async getEarned() {
    let earned = 0
    let totalEarned = 0
    if (this.state.wallet.account) {
      let result = await this.state.contractStake.methods.earned(this.state.wallet.account).call()
      console.log('result', result)
      earned = parseInt(result) / 10 ** TOKEN_INFO[this.state.poolInfo.stakeToken].decimal
      // totalEarned = parseInt(result.totalReward) / 10 ** TOKEN_INFO[this.state.poolInfo.stakeToken].decimal
      this.setState({ earned })
    }
  }

  async getStakeHistory() {
    let historyArray = []
    // let a = []s
    let stakedAmount = 0 //* Get Balance staked from Contract
    let availableStakedAmount = 0

    if (this.state.wallet.account) {
      // historyArray = await this.state.contractStake.methods.getStakeSlots(this.state.wallet.account).call()

      await this.state.contractStake.getPastEvents('Staked', {
        filter: { user: this.state.wallet.account },//this.state.wallet.account
        fromBlock: 0,
        toBlock: 'latest'
      })
        .then(async function (events) {
          // console.log('event', events)
          for (const i of events) {
            let time = await blockTimestamp(i.blockNumber)
            historyArray.push({ startAt: time, amount: i.returnValues.amount});
            // console.log('historyArray', historyArray)
          }

        });


      availableStakedAmount = await this.state.contractStake.methods.balanceOf(this.state.wallet.account).call()
      // console.log('availableStakedAmount', availableStakedAmount )
      availableStakedAmount = safeAmount(availableStakedAmount, TOKEN_INFO[this.state.poolInfo.stakeToken].decimal, 12)
      // console.log('safe', availableStakedAmount)



      //* TESTING
      // for (const i of historyArray) {
      //   stakedAmount = stakedAmount + parseInt(i.amount) / 10 ** TOKEN_INFO[this.state.poolInfo.stakeToken].decimal
      // }




      this.setState({ stakedAmount, availableStakedAmount, historyArray })
    }
  }

  convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
      "0." +
      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
      lead +
      decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }





  settle() {
    const web3 = window.web3;
    this.state.contractStake.methods
      .getReward()
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        $("#loadingModal").modal('show');
        this.setState({ txHash: hash, settleStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again
          this.getUserInfo(this.state.poolInfo.stakeToken, this.state.wallet.account)
          this.getContractInfo()
          this.setState({ settleStatus: "success" })
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');
        this.setState({ settleStatus: 'error' });
        console.log("errr " + error);
      });
  }


  withdraw(amount) {
    const web3 = window.web3;
    let withdrawAmountDecimal = this.convert(amount * 10 ** TOKEN_INFO[this.state.poolInfo.stakeToken].decimal).toString()
    console.log('withdrawAmountDecimal', withdrawAmountDecimal)
    this.state.contractStake.methods
      .withdraw(withdrawAmountDecimal)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        $("#loadingModal").modal('show');
        this.setState({ txHash: hash, settleStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again
          this.getUserInfo(this.state.poolInfo.stakeToken, this.state.wallet.account)
          this.getContractInfo()
          this.setState({ settleStatus: "success" })
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');
        this.setState({ settleStatus: 'error' });
        console.log("errr " + error);
      });
  }

  async stake() {
    if (this.state.stakeAmount <= 0) {
      return alert("Invalid Amount: Please check your amount!");
    }
    if (this.state.stakeAmount > this.state.balance) {
      return alert("Insufficient balance!");
    }
    this.depositBank(this.state.poolInfo.stakeToken)
  }

  depositBank(coin) {
    const web3 = window.web3;
    let stakeAmountDecimal = this.convert(this.state.stakeAmount * 10 ** TOKEN_INFO[coin].decimal).toString()
    console.log('stakeAmountDecimal', stakeAmountDecimal)

    this.setState({ stakeStatus: false });
    this.state.contractStake.methods
      .stake(stakeAmountDecimal)
      .send({
        from: this.state.wallet.account,
        gasPrice: web3.eth.gasPrice,
        gasLimit: web3.eth.getBlock("latest").gasLimit,
      })
      .on("transactionHash", (hash) => {
        $("#loadingModal").modal('show');
        this.setState({ txHash: hash, stakeStatus: "pending" });
      })
      .on("confirmation", (confNumber, receipt) => {
        if (confNumber === CONFIRM_NUMBER) {//6 confirmations for TX confirm 
          $("#loadingModal").modal('hide');
          // check balance and allowed amount again
          this.getUserInfo(this.state.poolInfo.stakeToken, this.state.wallet.account)
          this.getContractInfo()
          this.setState({ stakeStatus: "success" })
        }
      })
      .on("error", (error) => {
        $("#loadingModal").modal('hide');
        this.setState({ stakeStatus: 'error' });
        console.log("errr " + error);
      });
  }


  render() {
    let disableApprove = this.state.stakeAmount <= this.state.allowedAmount

    let approveButton = <button className={"btn btn-lg btn-block btn-brand " + (disableApprove && 'd-none')} type="button" onClick={(event) => { event.preventDefault(); this.approve(); }}>{(this.state.approveStatus === "success" || this.state.stakeAmount <= this.state.allowedAmount) && "Approved"}{this.state.approveStatus === "pending" && "Waiting..."}{this.state.approveStatus !== "pending" && this.state.approveStatus !== "success" && this.state.stakeAmount > this.state.allowedAmount && "Approve"}</button>
    let stakeButton = <button className={"btn btn-lg btn-block " + (!disableApprove ? 'd-none' : 'btn-success')} type="button" onClick={(event) => { event.preventDefault(); this.stake(); }} disabled={!disableApprove}> Stake</button>
    let unStakeButton = <button className="btn btn-lg btn-block btn-danger" type="button" onClick={(event) => { event.preventDefault(); this.withdraw(this.state.unStakeAmount); }} disabled={this.state.unStakeAmount == 0 || this.state.availableStakedAmount === 0}> Unstake </button>

    // <button onClick={(event) => { event.preventDefault(); this.approve(); }} className="btn btn-lg btn-block btn-cash" disabled={this.state.wallet.status!=='connected'}>Approve {this.state.poolInfo && this.state.poolInfo.stakeToken}</button>

    return (
      <main className="main mb-5">
        {this.state.poolInfo === null ? '' : <>
          <section className="hero">
            <div className="container">
              <div className="row">
                <div className="col-md-8 text-brand text-bold text-left">
                  <a href={'https://info.uniswap.org/pair/' + TOKEN_INFO[this.state.poolInfo.stakeToken].address}>
                    <h1 className="text-bold">Uniswap LP Farming</h1>
                  </a>
                  <h6 className="text-muted">Contract address <a href={'https://info.uniswap.org/pair/' + TOKEN_INFO[this.state.poolInfo.stakeToken].address}>{shortAddress(TOKEN_INFO[this.state.poolInfo.stakeToken].address)}</a></h6>
                </div>
                <div className="col-md-4 text-brand text-right">
                  <div className="apr"><sup className="text-3">APY</sup> <span className="text-5 text-bold ">2215%</span></div>
                </div>
              </div>
            </div>
          </section>
          {this.state.wallet.status === 'connected' ? <section className="pricing">
            <div className="container">
              <div className="row statis-bar mb-5 d-flex justify-content-center">
                <div className="col-md-3">
                  <div className="text-muted text-sm">
                    Your Balance
                  </div>
                  <div className="number">
                    {numeral(this.state.balance).format('0,0.[0000]')}
                  </div>
                  <div className="text-muted text-sm">
                    UNI V2
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-muted text-sm">
                    Currently Staked
                  </div>
                  <div className="number">
                    {numeral(this.state.availableStakedAmount).format('0,0.[0000]')}
                  </div>
                  <div className="text-muted text-sm">
                    UNI V2
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-muted text-sm">
                    Rewards Available
                  </div>
                  <div className="number">
                    {numeral(this.state.earned).format('0,0.[0000]')}
                  </div>
                  <div className="text-muted text-sm">
                    BLIZZ
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col-12 col-md-6">
                  <div className="tab-title-container d-flex justify-content-end">
                    <a href="#" className={"tab-title mr-2" + (this.state.stakeUnstake === 'unstake' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ stakeUnstake: 'unstake' }) }}>Unstake</a>
                    <a href="#" className={"tab-title" + (this.state.stakeUnstake === 'stake' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ stakeUnstake: 'stake' }) }}>Stake</a>
                  </div>
                  {this.state.stakeUnstake === 'stake' && <div className="card card-focus mb-4 py-4">
                    <div className="card-body text-left">
                      <p className="text-muted">Balance: <span className="number">{numeral(this.state.balance).format('0,0.[0000]')}</span> {this.state.poolInfo.stakeTokenName}</p>
                      <p className="card-text text-left text-muted mb-0">
                        <small>Amount:</small>
                        {this.state.stakeAmount !== this.state.balance && <a href="#" onClick={(e) => { e.preventDefault(); this.setMax() }} className="float-right badge badge-brand badge-pill">All +</a>}
                      </p>
                      <p className="card-text "><input
                        type="number"
                        name="stakeAmount"
                        className="form-control text-center stake-input number"
                        value={this.state.stakeAmount}
                        onChange={this.handleChange}
                      /></p>

                      <p className="card-text">
                        {approveButton}
                        {stakeButton}
                      </p>

                    </div>
                  </div>}
                  {this.state.stakeUnstake === 'unstake' && <div className="card card-focus mb-4 py-4">
                    <div className="card-body text-left">
                      <p className="text-muted">Staked: <span className="number">{this.state.availableStakedAmount}</span> {this.state.poolInfo.stakeTokenName}</p>
                      <p className="card-text text-left text-muted mb-0">
                        <small>Amount:</small>
                        {this.state.unStakeAmount !== this.state.availableStakedAmount && <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ unStakeAmount: this.state.availableStakedAmount }) }} className="float-right badge badge-brand badge-pill">All +</a>}
                      </p>
                      <p className="card-text "><input
                        type="number"
                        name="stakeAmount"
                        className="form-control text-center stake-input number"
                        value={this.state.unStakeAmount}
                        onChange={this.handleUnstateChange}
                      /></p>

                      <p className="card-text">
                        {unStakeButton}
                      </p>
                    </div>
                  </div>}

                </div>
                <div className="col-12 col-md-6">
                  <div className="tab-title-container d-flex justify-content-end">
                    <a href="#" className={"tab-title mr-2" + (this.state.historyClaim === 'history' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ historyClaim: 'history' }) }}>History</a>
                    <a href="#" className={"tab-title" + (this.state.historyClaim === 'claim' ? " active" : '')} onClick={(e) => { e.preventDefault(); this.setState({ historyClaim: 'claim' }) }}>Claim Rewards</a>
                  </div>
                  {this.state.historyClaim === 'history' && <div className="card card-focus mb-4 py-4">
                    <div className="card-body">
                      <p className="card-text">
                        {this.state.historyArray.length === 0 ? <h6 className="text-gray-soft text-regular">Empty history</h6> :
                          <>
                          <p className="text-2 text-bold">Stake History</p>
                          <table className="table">
                            <thead>
                              <tr className="text-brand">
                                <th scope="col">Index</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.historyArray.map((i, index) =>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{moment(parseInt(i.startAt) * 1000).format('DD/MM/YYYY hh:mm')}</td>
                                  <td>{safeAmount(i.amount, TOKEN_INFO[this.state.poolInfo.stakeToken].decimal)} UNI V2</td>
                                </tr>
                              )

                              }


                            </tbody>
                          </table>
                          </>
                        }
                      </p>

                    </div>
                  </div>}
                  {this.state.historyClaim === 'claim' && <div className="card card-focus mb-4 py-4">
                    <div className="card-body">
                      <p className="card-text">
                        <img src={Logos[this.state.poolInfo.getToken]} className="token-icon img-stroke mr-2" alt="blizzard cash" />
                      </p>
                      <p className="text-muted mb-0">Available</p>

                      <p className="card-text text-3  "><span className="number">{numeral(this.state.earned).format('0,0.[0000]')}</span> {this.state.poolInfo.getToken}</p>
                      <p className="card-text">
                        <button className="btn btn-lg btn-block btn-brand" onClick={(event) => { event.preventDefault(); this.settle(); }} disabled={this.state.earned === 0}>Claim BLIZZ</button>
                      </p>
                      {/* <p className="text-muted">Total reward: {numeral(this.state.totalEarned).format('0,0.[0000]')} ({this.state.poolInfo.getToken})</p> */}

                    </div>
                  </div>}
                </div>
                <div className="col-12">

                </div>

                {/* HISTORY   */}
                {/* <div className="col-12 mx-auto mb-4">
                  <h2>My stake history</h2>
                </div>
                <div className="col-12 col-lg-8 mx-auto">
                  {this.state.historyArray.length === 0 ? <h6 className="text-gray-soft text-regular">Empty history</h6> :


                    <table className="table table-responsive table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Index</th>
                          <th scope="col">Date</th>
                          <th scope="col">Staked Amount</th>
                          <th scope="col">Withdrawn Amount</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.historyArray.map((i, index) =>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{moment(parseInt(i.startAt) * 1000).format('DD MMM YYYY hh:mm Z')}</td>
                            <td>{parseInt(i.snapshotAmount) / 10 ** TOKEN_INFO[this.state.poolInfo.stakeToken].decimal} {this.state.poolInfo.stakeToken}</td>
                            <td>{(parseInt(i.snapshotAmount) - parseInt(i.amount)) / 10 ** TOKEN_INFO[this.state.poolInfo.stakeToken].decimal} {this.state.poolInfo.stakeToken}</td>
                            <td>{
                              parseInt(i.amount) === 0 ? <span className="badge badge-secondary">Ended</span> : <span className="badge badge-success">Running</span>
                            }</td>
                            <td><a className="badge badge-primary" onClick={(event) => { event.preventDefault(); this.withdraw(index) }} disabled={parseInt(i.amount) === 0}>Withdraw</a></td>
                          </tr>
                        )

                        }


                      </tbody>
                    </table>
                  }
                </div> */}

                  <a className="btn btn-lg btn-block btn-brand my-5"  target="_blank" href={'https://info.uniswap.org/pair/' + TOKEN_INFO[this.state.poolInfo.stakeToken].address}>
                    Go to UNISWAP pool <BsArrowUpRight />
                  </a>
              </div>
            </div>
          </section> : <div><button
            onClick={() => this.state.wallet.connect()}
            className="btn btn-lg btn-brand text-bold"
          >
            UNLOCK WALLET
                </button></div>}
        </>}




        <LoadingModal hash={this.state.txHash} />
      </ main>
    )
  }
}

export default withRouter(PoolInfo);